import mclogo from './minecraft.png';
import sharingan from './Mangekyo_Sharingan_Kakashi.png';
import './App.css';

// This is jsx and the return output looks like html. The function needs to be App.
function App() 
{

	var temp = <div className="LaunchPage">
		<header className="App-header">
		<h1>Gaming Fridays</h1>
		<img src={sharingan} className="App-logo" alt="Kakashi's Mangekyo Sharingan." />
		<p>
			{
				// If this were html, this would be showned literally
				// But instead 2 + 2 will be evaluated as 4. Also, comments
				// should be put inside curly braces else they'll appear
				// essentially as HTML on the web page.
				
				//2 + 2
				//- 1

				// Source code is stored in <code>src/App.js</code>. Save to reload this page.
				// target="_blank" in <a> is open in a new tab
			}
			Welcome to the launch site
		</p>
		<h2>Externally Available Sites</h2>
		<a
			className="App-link"
			href="https://h5ai.gamingfridays.org/"
			rel="noopener noreferrer">
			h5ai
		</a>
		<a
			className="App-link"
			href="https://mineos.gamingfridays.org/"
			rel="noopener noreferrer">
			Mine OS
		</a>
		<a
			className="App-link"
			href="https://gitlab.gamingfridays.org/"
			rel="noopener noreferrer">
			Gitlab
		</a>
		<h2>Internally Available Sites (TBD)</h2>
		{
			// ReactJS is a one html app, so internal.html won't work here on the same application.
			// Also, the curly braces cannot be on the same line for this.
		}
		</header>
	</div>
	
	return (temp);
}

export default App;
